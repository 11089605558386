import * as React from "react";
import { PureComponent } from "react";
import HeaderMenuItem from "./HeaderMenuItem";
import { MenuItemProps } from "./model";

export interface HeaderMenuProps {
	pages: MenuItemProps[];
	rightestIcons?: MenuItemProps[];
}

export default class HeaderMenu extends PureComponent<HeaderMenuProps> {
	renderMenuItems = () => {
		const { pages } = this.props;
		return pages.map((page) => {
			return <HeaderMenuItem key={page.to} {...page} />;
		});
	};

	render() {
		return <ul className="header-menu">{this.renderMenuItems()}</ul>;
	}
}
