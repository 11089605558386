import * as React from "react";
import { FiHome } from "react-icons/fi";
import Image from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";

const HomeButton = () => {
    const icon = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "menu-icon.png" }) {
                childImageSharp {
                    fixed(width: 42, height: 42) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);
    return (
        <Link className="home-button" to="/">
            {/* <FiHome size={28} /> */}
            <Image fixed={icon.image.childImageSharp.fixed} fadeIn={false} />
        </Link>
    );
};

export default HomeButton;
