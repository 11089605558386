import * as React from "react";
import { StaticQuery, graphql } from "gatsby";

import Header from "./Header/Header";
import "./layout.css";
// import "@themes/main.scss";
import "@themes/layout.scss";

const Layout = ({ children }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={(data) => (
			<div className="layout">
				<Header siteTitle={data.site.siteMetadata.title} />
				<main>{children}</main>
			</div>
		)}
	/>
);

export default Layout;
