import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { LocationProps } from "@reach/router";
import { SharpImageSource } from "templates/model";

interface SEOGraphQueried {
    defaultImage: SharpImageSource;
    site: {
        siteMetadata: {
            title: string;
            description: string;
            author: string;
            siteUrl: string;
        };
    };
}

export interface SEOProps {
    title: string;
    doNotAddNameSuffix?: boolean;
    description?: string;
    lang?: string;
    meta?: [];
    keywords?: string[];
    pagePath?: string;
    imagePath?: string;
}

const SEO = (props: SEOProps) => {
    const {
        description,
        lang,
        meta,
        keywords,
        title,
        doNotAddNameSuffix,
        pagePath,
        imagePath,
    } = props;
    const data = useStaticQuery<SEOGraphQueried>(
        graphql`
            query {
                defaultImage: file(
                    relativePath: { eq: "default-og-image.png" }
                ) {
                    childImageSharp {
                        fixed(width: 1200, height: 630) {
                            src
                        }
                    }
                }
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    );
    const { siteUrl } = data.site.siteMetadata;
    const metaDescription = description || data.site.siteMetadata.description;
    const defaultImagePath = data.defaultImage.childImageSharp.fixed.src;
    const canonicalUrl = pagePath ? `${siteUrl}${pagePath}` : null;
    const imageUrl = imagePath ? `${siteUrl}${imagePath}` : null;
    const defaultImageUrl = `${siteUrl}${defaultImagePath}`;
    const optionalTags = [];
    if (imageUrl) {
        optionalTags.push(
            {
                property: "og:image",
                content: imageUrl,
            },
            {
                property: "og:image:width",
                content: "1200",
            },
            {
                property: "og:image:height",
                content: "630",
            },
            {
                property: "twitter:image",
                content: imageUrl,
            }
        );
    } else {
        optionalTags.push(
            {
                property: "og:image",
                content: defaultImageUrl,
            },
            {
                property: "og:image:width",
                content: "1200",
            },
            {
                property: "og:image:height",
                content: "630",
            },
            {
                property: "twitter:image",
                content: defaultImageUrl,
            }
        );
    }
    if (canonicalUrl) {
        optionalTags.push({
            property: `og:url`,
            content: canonicalUrl,
        });
    }
    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={
                doNotAddNameSuffix
                    ? `%s`
                    : `%s | ${data.site.siteMetadata.title}`
            }
            link={[
                canonicalUrl && {
                    rel: "canonical",
                    href: canonicalUrl,
                },
            ]}
            meta={[
                ...optionalTags,
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: data.site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]
                .concat(
                    keywords.length > 0
                        ? {
                              name: `keywords`,
                              content: keywords.join(`, `),
                          }
                        : []
                )
                .concat(meta)}
        />
    );
};

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
};

export default SEO;
