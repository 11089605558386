import * as React from "react";
import { PureComponent } from "react";
import { MenuItemProps } from "./model";
import { Link } from "gatsby";

export interface HeaderMenuItemProps extends MenuItemProps {}

export default class HeaderMenuItem extends PureComponent<HeaderMenuItemProps> {
	headerMenuItemActiveStyle: React.CSSProperties;

	constructor(props: HeaderMenuItemProps) {
		super(props);
		this.headerMenuItemActiveStyle = {
			borderBottomStyle: "solid",
			borderBottomColor: "black",
			borderBottomWidth: "3.5px",
		};
	}

	render() {
		const { to, text, icon, hideText } = this.props;
		if (/^\/(?!\/)/.test(to)) {
			return (
				<li>
					<Link
						activeClassName="header-menu-item--state-active"
						to={to}>
						{icon}
						{text}
					</Link>
				</li>
			);
		} else {
			return (
				<li className="header-menu-item">
					<a target="_blank" href={to}>
						{icon}
						{text}
					</a>
				</li>
			);
		}
	}
}
