import * as React from "react";
import { Component } from "react";
import HeaderMenu from "./HeaderMenu";
import CollapsibleMenu from "./CollapsibleMenu";
import { MenuItemProps } from "./model";
import { IoLogoLinkedin, IoLogoGithub } from "react-icons/io";
import HomeButton from "./HomeButton";

import "@themes/header.scss";

export interface HeaderProps {
	siteTitle: string;
}

export interface HeaderState {
	menuShown: boolean;
}

export default class Header extends Component<HeaderProps, HeaderState> {
	collapsibleMenuRef: React.RefObject<CollapsibleMenu>;
	pages: MenuItemProps[];
	headerContacts: MenuItemProps[];
	collapsibleHeaderContacts: MenuItemProps[];

	constructor(props: HeaderProps) {
		super(props);
		this.state = {
			menuShown: false,
		};
		this.collapsibleMenuRef = React.createRef();

		this.pages = [
			{ to: "/", text: "Blogs" },
			{ to: "/projects", text: "Projects" },
			{ to: "/about", text: "About Me" },
		];

		this.headerContacts = this.pages;
	}

	render() {
		const { siteTitle } = this.props;
		return (
			<header>
				<div className="header-container">
					<HomeButton />
					<HeaderMenu
						pages={this.pages}
						rightestIcons={this.headerContacts}
					/>
				</div>
			</header>
		);
	}
}
